import React, { Component } from 'react';
import logo from './img/logo/30dayusa-logo@1x.svg'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>30 Day USA</p>
          <img src={logo} alt="logo of 30dayusa.com" />
          <p>COMING SOON!</p>
        </header>
      </div>
    );
  }
}

export default App;
